.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
}

.footer-light {
  background-color: #ffffff03;
}

.footer .footer-container {
  max-width: 1140px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 30px;
}

.footer .footer-left {
  display: flex;
  flex-direction: column;
}

.footer-button,
.footer-input {
  width: 100% !important;
}

.main-footer-input,
.footer-button {
  margin-bottom: 80px;
}

.footer .footer-left p {
  margin-bottom: 5px;
}

.footer .icons-social a {
  margin-right: 50px;
  font-size: 20px;
}

.footer .icons-social a:hover {
  opacity: 0.6;
  transition: all 0.3s ease-in-out;
}

.footer .footer-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer .footer-right .footer-right-menu {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 60px;
}

.footer .footer-right .footer-right-menu nav {
  border: none;
}

.footer .footer-right .footer-menu {
  margin-right: 50px;
}

.footer .footer-right .footer-menu nav ul {
  list-style: none;
  text-decoration: none;
}

.footer .footer-right .footer-menu li {
  margin-bottom: 30px;
  font-weight: 300;
  text-decoration: none;
}

.footer .footer-right .footer-menu li a {
  text-decoration: none;
  color: black;
}

.footer .footer-right .footer-menu li a {
  text-decoration: none;
  color: black;
}

.footer .footer-right .footer-menu-light li a {
  color: white;
}

.footer-menu-light {
  color: white !important ;
}

.footer-menu-light li a:hover {
  color: #ffffff9d !important;
  transition: all 0.4s ease-in-out;
}

.footer .footer-right .footer-menu li a:hover {
  color: rgba(0, 0, 0, 0.5);
  transition: all 0.4s ease-in-out;
}

@media all and (max-width: 660px) {
  .footer .footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
  }

  .footer .icons-social {
    margin: auto;
    width: 85%;
  }

  .footer .icons-social a {

    margin: 0;
  }

  .footer .footer-container .footer-right-menu {
    justify-content: space-between;
  }

  .footer .footer-container nav {
    padding: 0px;
  }

  .footer .icons-social {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
  }
}
