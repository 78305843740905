.main-input {
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.05);
  min-height: 50px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-radius: 4px;
}

.input {
  min-width: 0;
  flex: 1;
  text-decoration: none;
  margin-left: 15px;
  margin-right: 15px;
  border: none;
  font-size: 18px;
  font-weight: 300;
}

.input-error {
  color: red;
}

.input::placeholder {
  font-size: 14px;
  font-weight: 300;
  color: black;
}

.button-input {
  margin-right: 20px;
  width: 20px;
}

.button-input:hover {
  opacity: 0.6;
  transition: 0.2s;
}

.button-input:active {
  width: 18px;
}
.error-list {
  margin-left: 10px;
  margin-top: 5px;
}
.error-list ul li {
  list-style: none;
}
.error-list ul li small {
  font-weight: 300;
  color: red;
}
