.header {
  background: linear-gradient(
    360deg,
    rgba(245, 246, 249, 0.8) 100%,
    #fff 43.95%
  );
  display: flex;
  flex-direction: column;
}

.header .header-container {
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 15px;
}

.header .header-container h2 {
  color: #626262;
  text-align: center;
  line-height: 134%;
  margin-top: 80px;
  letter-spacing: -0.05em;
  font-weight: 300;
  color: #0062ff;
}

.header .header-container p {
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;
  letter-spacing: -0.04em;
  line-height: 163.5%;
}

.header .header-container button {
  align-self: center;
  margin-bottom: 60px;
}

.header .header-container .header-video {
  margin: 0 auto ;
  width: 560px;
  height: 315px;
  margin-bottom: 35px;
  border: 0;
  box-shadow: 0 0px 100px grey;
}

@media all and (max-width: 600px) {
  .header .header-container {
    width: 90%;
    
  }
  
.header .header-container button {
  align-self: normal;
  margin-bottom: 60px;
}
.header .header-container .header-video{
  width:90% ;
  height: 220px;
}
}

@media all and (max-width: 425px) {
  .header .header-container .header-video{
    width:100% ;
    height: 190px;
  }
}
