.about-us-page {
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-us-page .about-us-container {
  max-width: 900px;
  width: 90%;
}

.about-us-page .about-us-container h2 {
  margin-top: 125px;
  margin-bottom: 80px;
  letter-spacing: -0.04em;
  text-align: center;
  font-weight: 500;
}

.about-us-page .about-us-container p {
  color: #838383;
  letter-spacing: -0.04em;
  line-height: 40px;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
}


.about-us-page .objective-card {
  border: 1px solid #e7e7e7;
  padding: 60px;
  border-radius: 40px;
  max-width: 500px;
  width: 400px;
  height: 400px;
  margin: 20px;
}
.about-us-page .about-us-video {
  width: 560px;
  height: 315px;
  border: 0;
  box-shadow: 0 0px 100px grey;
}

.about-us-page .objective-title {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -0.05em;
  margin-bottom: 40px;
}

.about-us-page .objective-description {
  color: #838383;
  line-height: 40px;
  font-size: 18px;
}

@media all and (max-width: 600px) {
  .about-us-page .objective-card {
    width: 90%;
    height: auto;
    padding: 30px;
    padding-top: 40px;
  }

  .about-us-page .objective-description {
    color: #838383;
    line-height: 40px;
  }
  .about-us-page .about-us-video {
    width: 90%;
    height: 220px;    
  }
}

@media all and (max-width: 425px) {
  .about-us-page .about-us-video {
    width: 95%;
    height: 190px;
  }
}
