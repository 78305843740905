.institution-card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  max-width: 380px;
}

.institution-card-container .institution-card {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 10px 10px 25px rgba(111, 111, 111, 0.06);
  width: 155px;
  height: 155px;
  margin: 12.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media all and (max-width:620px) {
  .institution-card-container .institution-card {
    width: 120px;
    height: 120px;
    margin: 10px;
  }
}
