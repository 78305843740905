.main-comic {
  color: #8bb9ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: auto;
}

.comic-main-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comic-main-section .comic-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15vh;
}

.comic-menu-main-section {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.comic-main-image {
  z-index: -100;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 80%;
  opacity: 0.15;
}

#comic-main-logo {
  width: 120px;
}

#comic-main-title {
  color: #8bb9ff;
  font-size: 160px;
  letter-spacing: -0.05em;
  margin: 0px;
}

#comic-main-subtitle {
  font-weight: 300;
  font-size: 22px;
  text-align: center;
}

#arrow-back:hover {
  opacity: 0.8;
}

@media all and (max-width: 550px) {
  .comic-main-section .comic-header {
    margin-top: 12vh;
    margin-bottom: 0;
  }

  .main-comic {
    width: 95%;
  }

  #comic-main-title {
    font-size: 80px;
  }

  #comic-main-subtitle {
    font-size: 20px;
  }
}
