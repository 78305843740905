.faqs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
}

.faqs h2 {
  letter-spacing: -0.06ms;
  font-weight: 400;
  text-align: center;
  margin: 80px;
  font-size: 36px;
}

.faq {
  background-color: transparent;
  max-width: 800px;
  width: 90%;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 11px;
  margin-bottom: 15px;
}

.faq .faq-question-container {
  display: flex;
  padding: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: all 0.4s ease;
}

.faq .faq-question-container p {
  margin-left: 10px;
}

.faq .faq-question-container img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  fill: #838383;
}
