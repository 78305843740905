.feature {
  display: flex;
  justify-content: center;
}

.feature-container {
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.feature-container .feature-right {
  max-width: 550px;
  margin: 60px;
  text-align: justify;
}

.feature-container .feature-right .feature-title {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 40px;
  letter-spacing: -0.06em;
}

.feature-container .feature-right .feature-description {
  font-size: 22px;
  font-weight: 300;
  line-height: 45px;
  letter-spacing: -0.06em;
}

@media all and (max-width: 1000px) {
  .feature-container {
    flex-direction: column-reverse !important;
  }

  .feature-container .feature-right {
    max-width: 100%;
    text-align: center;
  }

  .feature-container .feature-left {
    width: 80%;
  }
}

@media all and (max-width: 600px) {
  .feature-container .feature-right .feature-description {
    font-size: 18px;
    font-weight: 300;
    line-height: 35px;
    letter-spacing: -0.05em;
  }

  .feature-container .feature-right {
    margin: 25px;
    margin-top: 40px;
  }
}
