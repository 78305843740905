@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;531;600;700;800;900&display=swap");
html {
  scroll-behavior: smooth;
}

* {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
}

.background-body {
  background-image: url("../assets//img/background-image.png");
  background-position-x: right;
  background-repeat: no-repeat;
}

input:focus,
textarea:focus,
select:focus,
a:focus,
button:focus {
  outline: none;
}

h1 {
  font-size: 72px;
}

h2 {
  font-size: 58px;
}

h3 {
  font-size: 34px;
}

h4 {
  font-size: 32px;
}
p {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.02em;
}

.subtitle {
  font-size: 24px;
  font-weight: 300;
}

/*Media Query Tablet*/
@media all and (max-width: 600px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 36px;
  }

  h4 {
    font-size: 32px;
  }

  h5 {
    font-size: 24px;
  }
  p {
    font-size: 14px;
  }

  .subtitle {
    font-size: 22px;
  }
}

/*Media Query Mobile*/
@media all and (max-width: 400px) {
  h1 {
    font-size: 44px;
  }

  h2 {
    font-size: 32px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 20px;
  }
  p {
    font-size: 14px;
  }

  .subtitle {
    font-size: 18px;
  }
  
}

/*Media Query SmallMobile*/
@media all and (max-width: 360px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 28px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 20px;
  }
  p {
    font-size: 14px;
  }

  .subtitle {
    font-size: 18px;
  }
}
