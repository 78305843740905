.terms-and-conditions-page {
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.terms-and-conditions-page .terms-and-conditions-container {
  max-width: 900px;
  width: 90%;
}

.terms-and-conditions-page .terms-and-conditions-container h2 {
  margin-top: 125px;
  margin-bottom: 80px;
  letter-spacing: -0.04em;
  text-align: center;
  font-weight: 500;
}

.terms-and-conditions-page .terms-and-conditions-container h4 {
  letter-spacing: -0.04em;
  font-size: 24px;
  margin-bottom: 35px;
  margin-top: 35px;
  font-weight: 500;
}

.terms-and-conditions-page .terms-and-conditions-container p {
  color: #838383;
  letter-spacing: -0.04em;
  font-weight: 300;
  font-size: 14px;
  text-align: justify;
}

@media all and (max-width: 360px) {
  .terms-and-conditions-page .terms-and-conditions-container h2 {
    margin-top: 80px;
  }
}
