.download-page {
  max-width: 1440px;
  display: flex;
  margin: auto;
  flex-direction: column;
  transition: all 0.4s ease-in-out;
}

.download-page h1 {
  margin-top: 20vh;
}

.download-page p {
  font-weight: 300;
  margin-top: 20px;
  text-align: center;
}

.download-page .download-options p {
  font-size: 24px;
  margin-top: 8em;
  letter-spacing: -0.04em;
  text-align: center;
}

.download-page-menu {
  height: 60px;
}

.download-page .download-options {
  margin: 30px;
}

.download-page .secondary {
  margin-bottom: 10em;
  margin-top: 0;
}

.download-page .download-options i {
  font-size: 85px;
  margin: 40px;
  margin-top: 120px;
  color: #0062ff;
}

.download-page .main-download-page-input {
  margin-bottom: 20vh;
  width: 600px;
  align-self: center;
}

.download-page .download-options .secondary {
  display: flex;
  flex-direction: row;
}

.download-page h1 {
  font-weight: 600;
  letter-spacing: -0.05em;
  text-align: center;
}

.download-page .download-page-input {
  margin-top: 120px;
  box-shadow: none;
  border: 1px solid #ededed;
  border-radius: 13px;
  height: 63px;
}

.download-page .download-page-input:hover {
  border: 1px solid #626262;
  transition: all 0.4s ease-in-out;
}

.download-page input::placeholder {
  color: #838383;
  font-size: 18px;
}

@media all and (max-width: 600px) {
  .download-page {
    width: 90%;
  }

  .download-page h1 {
    margin-top: 10vh;
  }

  .download-page .download-page-input {
    margin-top: 60px;
  }
  .download-page p {
    margin-bottom: 1vh;
  }

  .download-page-menu {
    height: auto;
  }

  .download-page .download-options p {
    margin-top: 5em;
    margin-bottom: 3em;
  }

  .download-page .download-options i {
    margin-top: 20px;
  }

  .download-page .main-download-page-input {
    width: auto;
    align-self: normal;
  }
}
