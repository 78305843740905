.institution-connection-block {
  background: rgba(0, 98, 255, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.institution-connection-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  width: 90%;
  margin-top: 60px;
  margin-bottom: 60px;
}

.institution-connection-block .institution-left {
  display: flex;
  flex-direction: column;
}

.institution-connection-block .institution-left h2 {
  margin-bottom: 85px;
  font-weight: 500;
  letter-spacing: -0.06em;
  color: #0062ff;
}

.institution-connection-block .institution-left button {
  align-self: flex-start;
}

@media all and (max-width: 900px) {
  .goals-section .goals-card .goals-card-container img {
    margin-bottom: 30px;
  }

  .institution-connection-block .institution-left h2 {
    margin-bottom: 60px;
    text-align: center;
  }

  .institution-connection-block .institution-left button {
    align-self: normal;
  }

  .institution-connection-block .institution-left button {
    margin-bottom: 60px;
  }

  .institution-connection-container {
    flex-direction: column;
  }
}

@media all and (max-width: 360px) {
  .institution-card-container {
    flex-wrap: wrap;
  }
}
