.main-comic-section {
  display: flex;
  justify-content: center;
  height: 300px;
  background-color: #07216e;
}

.comic-section {
  position: relative;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1200px;
}

.comic-section-image {
  position: absolute;
  width: 40%;
  margin: auto;
  top: 0;
  opacity: 0.15;
}

.comic-section-title {
  z-index: 100;
  margin: 0;
  font-size: 80px;
  color: #8bb9ff;
}

#comic-section-logo {
  z-index: 100;
  width: 150px;
}

@media all and (max-width: 1000px) {
  .comic-section-image {
    width: 80%;
  }
}


@media all and (max-width: 360px) {
  .comic-section-image {
    width: 100%;
  }
  #comic-section-logo {
    width: 100px;
  }
  .comic-section-title {
    font-size: 60px;
  }
}
