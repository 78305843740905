@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;531;600;700;800;900&display=swap);
.main-comic-section {
  display: flex;
  justify-content: center;
  height: 300px;
  background-color: #07216e;
}

.comic-section {
  position: relative;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1200px;
}

.comic-section-image {
  position: absolute;
  width: 40%;
  margin: auto;
  top: 0;
  opacity: 0.15;
}

.comic-section-title {
  z-index: 100;
  margin: 0;
  font-size: 80px;
  color: #8bb9ff;
}

#comic-section-logo {
  z-index: 100;
  width: 150px;
}

@media all and (max-width: 1000px) {
  .comic-section-image {
    width: 80%;
  }
}


@media all and (max-width: 360px) {
  .comic-section-image {
    width: 100%;
  }
  #comic-section-logo {
    width: 100px;
  }
  .comic-section-title {
    font-size: 60px;
  }
}

.button {
  transition: 0.3s ease all;
  cursor: pointer;
  border-radius: 6px;
   border: none;
}

.hover-dark:hover {
  background-color: #002fff !important;
}

.hover-light:hover {
  background-color: #0062ff15 !important;
}

.hover-opacity-dark:hover {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.hover-opacity-light:hover {
  background-color: rgba(255, 255, 255, 0.15) !important;
}

.button:active {
  opacity: 0.8;
  transition: all 0.1s ease-in-out;
}

.faqs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
}

.faqs h2 {
  letter-spacing: -0.06ms;
  font-weight: 400;
  text-align: center;
  margin: 80px;
  font-size: 36px;
}

.faq {
  background-color: transparent;
  max-width: 800px;
  width: 90%;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 11px;
  margin-bottom: 15px;
}

.faq .faq-question-container {
  display: flex;
  padding: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: all 0.4s ease;
}

.faq .faq-question-container p {
  margin-left: 10px;
}

.faq .faq-question-container img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  fill: #838383;
}

.feature {
  display: flex;
  justify-content: center;
}

.feature-container {
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.feature-container .feature-right {
  max-width: 550px;
  margin: 60px;
  text-align: justify;
}

.feature-container .feature-right .feature-title {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 40px;
  letter-spacing: -0.06em;
}

.feature-container .feature-right .feature-description {
  font-size: 22px;
  font-weight: 300;
  line-height: 45px;
  letter-spacing: -0.06em;
}

@media all and (max-width: 1000px) {
  .feature-container {
    flex-direction: column-reverse !important;
  }

  .feature-container .feature-right {
    max-width: 100%;
    text-align: center;
  }

  .feature-container .feature-left {
    width: 80%;
  }
}

@media all and (max-width: 600px) {
  .feature-container .feature-right .feature-description {
    font-size: 18px;
    font-weight: 300;
    line-height: 35px;
    letter-spacing: -0.05em;
  }

  .feature-container .feature-right {
    margin: 25px;
    margin-top: 40px;
  }
}

.main-input {
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.05);
  min-height: 50px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-radius: 4px;
}

.input {
  min-width: 0;
  flex: 1 1;
  text-decoration: none;
  margin-left: 15px;
  margin-right: 15px;
  border: none;
  font-size: 18px;
  font-weight: 300;
}

.input-error {
  color: red;
}

.input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 300;
  color: black;
}

.input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 300;
  color: black;
}

.input::-ms-input-placeholder {
  font-size: 14px;
  font-weight: 300;
  color: black;
}

.input::placeholder {
  font-size: 14px;
  font-weight: 300;
  color: black;
}

.button-input {
  margin-right: 20px;
  width: 20px;
}

.button-input:hover {
  opacity: 0.6;
  transition: 0.2s;
}

.button-input:active {
  width: 18px;
}
.error-list {
  margin-left: 10px;
  margin-top: 5px;
}
.error-list ul li {
  list-style: none;
}
.error-list ul li small {
  font-weight: 300;
  color: red;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
}

.footer-light {
  background-color: #ffffff03;
}

.footer .footer-container {
  max-width: 1140px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 30px;
}

.footer .footer-left {
  display: flex;
  flex-direction: column;
}

.footer-button,
.footer-input {
  width: 100% !important;
}

.main-footer-input,
.footer-button {
  margin-bottom: 80px;
}

.footer .footer-left p {
  margin-bottom: 5px;
}

.footer .icons-social a {
  margin-right: 50px;
  font-size: 20px;
}

.footer .icons-social a:hover {
  opacity: 0.6;
  transition: all 0.3s ease-in-out;
}

.footer .footer-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer .footer-right .footer-right-menu {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 60px;
}

.footer .footer-right .footer-right-menu nav {
  border: none;
}

.footer .footer-right .footer-menu {
  margin-right: 50px;
}

.footer .footer-right .footer-menu nav ul {
  list-style: none;
  text-decoration: none;
}

.footer .footer-right .footer-menu li {
  margin-bottom: 30px;
  font-weight: 300;
  text-decoration: none;
}

.footer .footer-right .footer-menu li a {
  text-decoration: none;
  color: black;
}

.footer .footer-right .footer-menu li a {
  text-decoration: none;
  color: black;
}

.footer .footer-right .footer-menu-light li a {
  color: white;
}

.footer-menu-light {
  color: white !important ;
}

.footer-menu-light li a:hover {
  color: #ffffff9d !important;
  transition: all 0.4s ease-in-out;
}

.footer .footer-right .footer-menu li a:hover {
  color: rgba(0, 0, 0, 0.5);
  transition: all 0.4s ease-in-out;
}

@media all and (max-width: 660px) {
  .footer .footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
  }

  .footer .icons-social {
    margin: auto;
    width: 85%;
  }

  .footer .icons-social a {

    margin: 0;
  }

  .footer .footer-container .footer-right-menu {
    justify-content: space-between;
  }

  .footer .footer-container nav {
    padding: 0px;
  }

  .footer .icons-social {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
  }
}

.goals-section {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 100px;
  max-width: 1440px;
}
.goals-section .goals-img {
  max-width: 1156px;
  margin: 0 auto;
  width: 100%;
}

.goals-section .goals-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1140;
  margin-top: 80px;
}

.goals-section h3 {
  text-align: center;
  margin-top: 60px;
  font-weight: 400;
  letter-spacing: -0.06em;
}

.goals-section button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

.goals-section .goals-card .goals-card-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 60px;
  margin-bottom: 75px;
}

.goals-section .goals-card .goals-card-container img {
  width: 75px;
  height: 75px;
  margin-right: 25px;
}

@media all and (max-width: 600px) {
  .goals-section {
    width: 90%;
    margin-top: 40px;
  }
  .goals-section .goals-card .goals-card-container img {
    width: 64px;
    height: 64px;
    margin-right: 15px;
    margin-bottom: 30px;
  }
  .goals-section .goals-card .goals-card-container {
    margin-left: 0px;
    margin-bottom: 40px;
  }
  .goals-section .goals-img {
    width: 100%;
    margin-bottom: 0%;
  }
}

.menu-hamburguer-collapsed {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 100;
  transition: all 0.4s ease-in-out;
}

.menu-hamburguer-collapsed .menu-hamburguer-collapsed-header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
  border-bottom: 1px solid #efefef;
}

.menu-hamburguer-collapsed .menu-hamburguer-collapsed-menu {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-hamburguer-collapsed
  .menu-hamburguer-collapsed-menu
  .menu-hamburguer-collapsed-menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.menu-hamburguer-collapsed .menu-hamburguer-collapsed-menu li {
  list-style: none;
  margin-bottom: 50px;
}

.menu-hamburguer-collapsed .menu-hamburguer-collapsed-menu li a {
  font-size: 20px;
  font-weight: 300;
  text-decoration: none;
  color: #626262;
}

.menu-hamburguer-collapsed .menu-hamburguer-collapsed-menu li a:hover {
  opacity: 0.6;
  transition: all 0.4s ease-in-out;
}

@media all and (max-width:360px) {

}
nav {
  padding: 15px;
  border-bottom: 1px solid #efefef;
  display: flex;
  justify-content: center;
}

.menu-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
}

.menu-container .menu-logo {
  width: 120px !important;
}

.menu-container .menu-hamburguer {
  width: 30px;
  display: none;
}

/* List-Elements */
.menu-container ul {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* Links */
.menu-container ul a {
  text-decoration: none;
  color: #9f9f9f;
}

.menu-container ul a:hover {
  color: black;
  transition: 1.5s;
}

.menu-container ul a:active {
  color: black;
  transition: 1.5s;
}

@media all and (max-width: 620px) {
  .menu-container ul {
    display: none;
  }

  .menu-container .menu-logo {
    width: 100px !important;
  }
  .menu-container .menu-hamburguer {
    display: flex;
  }

  .menu-container .menu-hamburguer:hover {
    opacity: 0.5;
    transition: all 0.4s ease-in-out;
  }
  .menu-container .menu-hamburguer:active {
    transition: none;
    width: 28px;
  }

  .menu-container .menu-hamburguer {
    transition: none;
    width: 28px;
  }
}

.header {
  background: linear-gradient(
    360deg,
    rgba(245, 246, 249, 0.8) 100%,
    #fff 43.95%
  );
  display: flex;
  flex-direction: column;
}

.header .header-container {
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 15px;
}

.header .header-container h2 {
  color: #626262;
  text-align: center;
  line-height: 134%;
  margin-top: 80px;
  letter-spacing: -0.05em;
  font-weight: 300;
  color: #0062ff;
}

.header .header-container p {
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;
  letter-spacing: -0.04em;
  line-height: 163.5%;
}

.header .header-container button {
  align-self: center;
  margin-bottom: 60px;
}

.header .header-container .header-video {
  margin: 0 auto ;
  width: 560px;
  height: 315px;
  margin-bottom: 35px;
  border: 0;
  box-shadow: 0 0px 100px grey;
}

@media all and (max-width: 600px) {
  .header .header-container {
    width: 90%;
    
  }
  
.header .header-container button {
  align-self: normal;
  margin-bottom: 60px;
}
.header .header-container .header-video{
  width:90% ;
  height: 220px;
}
}

@media all and (max-width: 425px) {
  .header .header-container .header-video{
    width:100% ;
    height: 190px;
  }
}

.institution-card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  max-width: 380px;
}

.institution-card-container .institution-card {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 10px 10px 25px rgba(111, 111, 111, 0.06);
  width: 155px;
  height: 155px;
  margin: 12.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media all and (max-width:620px) {
  .institution-card-container .institution-card {
    width: 120px;
    height: 120px;
    margin: 10px;
  }
}

.institution-connection-block {
  background: rgba(0, 98, 255, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.institution-connection-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  width: 90%;
  margin-top: 60px;
  margin-bottom: 60px;
}

.institution-connection-block .institution-left {
  display: flex;
  flex-direction: column;
}

.institution-connection-block .institution-left h2 {
  margin-bottom: 85px;
  font-weight: 500;
  letter-spacing: -0.06em;
  color: #0062ff;
}

.institution-connection-block .institution-left button {
  align-self: flex-start;
}

@media all and (max-width: 900px) {
  .goals-section .goals-card .goals-card-container img {
    margin-bottom: 30px;
  }

  .institution-connection-block .institution-left h2 {
    margin-bottom: 60px;
    text-align: center;
  }

  .institution-connection-block .institution-left button {
    align-self: normal;
  }

  .institution-connection-block .institution-left button {
    margin-bottom: 60px;
  }

  .institution-connection-container {
    flex-direction: column;
  }
}

@media all and (max-width: 360px) {
  .institution-card-container {
    flex-wrap: wrap;
  }
}

html {
  scroll-behavior: smooth;
}

* {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
}

.background-body {
  background-image: url(/static/media/background-image.58b39417.png);
  background-position-x: right;
  background-repeat: no-repeat;
}

input:focus,
textarea:focus,
select:focus,
a:focus,
button:focus {
  outline: none;
}

h1 {
  font-size: 72px;
}

h2 {
  font-size: 58px;
}

h3 {
  font-size: 34px;
}

h4 {
  font-size: 32px;
}
p {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.02em;
}

.subtitle {
  font-size: 24px;
  font-weight: 300;
}

/*Media Query Tablet*/
@media all and (max-width: 600px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 36px;
  }

  h4 {
    font-size: 32px;
  }

  h5 {
    font-size: 24px;
  }
  p {
    font-size: 14px;
  }

  .subtitle {
    font-size: 22px;
  }
}

/*Media Query Mobile*/
@media all and (max-width: 400px) {
  h1 {
    font-size: 44px;
  }

  h2 {
    font-size: 32px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 20px;
  }
  p {
    font-size: 14px;
  }

  .subtitle {
    font-size: 18px;
  }
  
}

/*Media Query SmallMobile*/
@media all and (max-width: 360px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 28px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 20px;
  }
  p {
    font-size: 14px;
  }

  .subtitle {
    font-size: 18px;
  }
}

.about-us-page {
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-us-page .about-us-container {
  max-width: 900px;
  width: 90%;
}

.about-us-page .about-us-container h2 {
  margin-top: 125px;
  margin-bottom: 80px;
  letter-spacing: -0.04em;
  text-align: center;
  font-weight: 500;
}

.about-us-page .about-us-container p {
  color: #838383;
  letter-spacing: -0.04em;
  line-height: 40px;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
}


.about-us-page .objective-card {
  border: 1px solid #e7e7e7;
  padding: 60px;
  border-radius: 40px;
  max-width: 500px;
  width: 400px;
  height: 400px;
  margin: 20px;
}
.about-us-page .about-us-video {
  width: 560px;
  height: 315px;
  border: 0;
  box-shadow: 0 0px 100px grey;
}

.about-us-page .objective-title {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -0.05em;
  margin-bottom: 40px;
}

.about-us-page .objective-description {
  color: #838383;
  line-height: 40px;
  font-size: 18px;
}

@media all and (max-width: 600px) {
  .about-us-page .objective-card {
    width: 90%;
    height: auto;
    padding: 30px;
    padding-top: 40px;
  }

  .about-us-page .objective-description {
    color: #838383;
    line-height: 40px;
  }
  .about-us-page .about-us-video {
    width: 90%;
    height: 220px;    
  }
}

@media all and (max-width: 425px) {
  .about-us-page .about-us-video {
    width: 95%;
    height: 190px;
  }
}

.download-page {
  max-width: 1440px;
  display: flex;
  margin: auto;
  flex-direction: column;
  transition: all 0.4s ease-in-out;
}

.download-page h1 {
  margin-top: 20vh;
}

.download-page p {
  font-weight: 300;
  margin-top: 20px;
  text-align: center;
}

.download-page .download-options p {
  font-size: 24px;
  margin-top: 8em;
  letter-spacing: -0.04em;
  text-align: center;
}

.download-page-menu {
  height: 60px;
}

.download-page .download-options {
  margin: 30px;
}

.download-page .secondary {
  margin-bottom: 10em;
  margin-top: 0;
}

.download-page .download-options i {
  font-size: 85px;
  margin: 40px;
  margin-top: 120px;
  color: #0062ff;
}

.download-page .main-download-page-input {
  margin-bottom: 20vh;
  width: 600px;
  align-self: center;
}

.download-page .download-options .secondary {
  display: flex;
  flex-direction: row;
}

.download-page h1 {
  font-weight: 600;
  letter-spacing: -0.05em;
  text-align: center;
}

.download-page .download-page-input {
  margin-top: 120px;
  box-shadow: none;
  border: 1px solid #ededed;
  border-radius: 13px;
  height: 63px;
}

.download-page .download-page-input:hover {
  border: 1px solid #626262;
  transition: all 0.4s ease-in-out;
}

.download-page input::-webkit-input-placeholder {
  color: #838383;
  font-size: 18px;
}

.download-page input:-ms-input-placeholder {
  color: #838383;
  font-size: 18px;
}

.download-page input::-ms-input-placeholder {
  color: #838383;
  font-size: 18px;
}

.download-page input::placeholder {
  color: #838383;
  font-size: 18px;
}

@media all and (max-width: 600px) {
  .download-page {
    width: 90%;
  }

  .download-page h1 {
    margin-top: 10vh;
  }

  .download-page .download-page-input {
    margin-top: 60px;
  }
  .download-page p {
    margin-bottom: 1vh;
  }

  .download-page-menu {
    height: auto;
  }

  .download-page .download-options p {
    margin-top: 5em;
    margin-bottom: 3em;
  }

  .download-page .download-options i {
    margin-top: 20px;
  }

  .download-page .main-download-page-input {
    width: auto;
    align-self: normal;
  }
}

.main-comic {
  color: #8bb9ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: auto;
}

.comic-main-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comic-main-section .comic-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15vh;
}

.comic-menu-main-section {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.comic-main-image {
  z-index: -100;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 80%;
  opacity: 0.15;
}

#comic-main-logo {
  width: 120px;
}

#comic-main-title {
  color: #8bb9ff;
  font-size: 160px;
  letter-spacing: -0.05em;
  margin: 0px;
}

#comic-main-subtitle {
  font-weight: 300;
  font-size: 22px;
  text-align: center;
}

#arrow-back:hover {
  opacity: 0.8;
}

@media all and (max-width: 550px) {
  .comic-main-section .comic-header {
    margin-top: 12vh;
    margin-bottom: 0;
  }

  .main-comic {
    width: 95%;
  }

  #comic-main-title {
    font-size: 80px;
  }

  #comic-main-subtitle {
    font-size: 20px;
  }
}

.published-comic {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 300px;
  height: 430px;
  margin: 30px;
  border-radius: 15px;
  text-decoration: none;
  transition: 0.2s;
}

#comic-scroll:hover {
  opacity: 0.7;
}

.comic-page-input {
  width: 600px !important;
}

.published-comic:hover {
  height: 420px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  transition: 0.2s;
}
.published-comic-title {
  margin: 30px;
  color: white;
  font-size: 18px;
  font-weight: 400;
}


@media all and (max-width: 360px) {
  .comic-input {
    min-width: 320px;
    margin-bottom: 50%;
  }
  .published-comic {
    margin: 15px;
  }
}

@media all and (max-width: 600px) {
  .comic-page-input {
    width: 100% !important
  }
}

.terms-and-conditions-page {
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.terms-and-conditions-page .terms-and-conditions-container {
  max-width: 900px;
  width: 90%;
}

.terms-and-conditions-page .terms-and-conditions-container h2 {
  margin-top: 125px;
  margin-bottom: 80px;
  letter-spacing: -0.04em;
  text-align: center;
  font-weight: 500;
}

.terms-and-conditions-page .terms-and-conditions-container h4 {
  letter-spacing: -0.04em;
  font-size: 24px;
  margin-bottom: 35px;
  margin-top: 35px;
  font-weight: 500;
}

.terms-and-conditions-page .terms-and-conditions-container p {
  color: #838383;
  letter-spacing: -0.04em;
  font-weight: 300;
  font-size: 14px;
  text-align: justify;
}

@media all and (max-width: 360px) {
  .terms-and-conditions-page .terms-and-conditions-container h2 {
    margin-top: 80px;
  }
}

