.button {
  transition: 0.3s ease all;
  cursor: pointer;
  border-radius: 6px;
   border: none;
}

.hover-dark:hover {
  background-color: #002fff !important;
}

.hover-light:hover {
  background-color: #0062ff15 !important;
}

.hover-opacity-dark:hover {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.hover-opacity-light:hover {
  background-color: rgba(255, 255, 255, 0.15) !important;
}

.button:active {
  opacity: 0.8;
  transition: all 0.1s ease-in-out;
}
