.goals-section {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 100px;
  max-width: 1440px;
}
.goals-section .goals-img {
  max-width: 1156px;
  margin: 0 auto;
  width: 100%;
}

.goals-section .goals-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1140;
  margin-top: 80px;
}

.goals-section h3 {
  text-align: center;
  margin-top: 60px;
  font-weight: 400;
  letter-spacing: -0.06em;
}

.goals-section button {
  width: fit-content;
  margin: auto;
}

.goals-section .goals-card .goals-card-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 60px;
  margin-bottom: 75px;
}

.goals-section .goals-card .goals-card-container img {
  width: 75px;
  height: 75px;
  margin-right: 25px;
}

@media all and (max-width: 600px) {
  .goals-section {
    width: 90%;
    margin-top: 40px;
  }
  .goals-section .goals-card .goals-card-container img {
    width: 64px;
    height: 64px;
    margin-right: 15px;
    margin-bottom: 30px;
  }
  .goals-section .goals-card .goals-card-container {
    margin-left: 0px;
    margin-bottom: 40px;
  }
  .goals-section .goals-img {
    width: 100%;
    margin-bottom: 0%;
  }
}
