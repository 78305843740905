nav {
  padding: 15px;
  border-bottom: 1px solid #efefef;
  display: flex;
  justify-content: center;
}

.menu-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
}

.menu-container .menu-logo {
  width: 120px !important;
}

.menu-container .menu-hamburguer {
  width: 30px;
  display: none;
}

/* List-Elements */
.menu-container ul {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* Links */
.menu-container ul a {
  text-decoration: none;
  color: #9f9f9f;
}

.menu-container ul a:hover {
  color: black;
  transition: 1.5s;
}

.menu-container ul a:active {
  color: black;
  transition: 1.5s;
}

@media all and (max-width: 620px) {
  .menu-container ul {
    display: none;
  }

  .menu-container .menu-logo {
    width: 100px !important;
  }
  .menu-container .menu-hamburguer {
    display: flex;
  }

  .menu-container .menu-hamburguer:hover {
    opacity: 0.5;
    transition: all 0.4s ease-in-out;
  }
  .menu-container .menu-hamburguer:active {
    transition: none;
    width: 28px;
  }

  .menu-container .menu-hamburguer {
    transition: none;
    width: 28px;
  }
}
