.menu-hamburguer-collapsed {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 100;
  transition: all 0.4s ease-in-out;
}

.menu-hamburguer-collapsed .menu-hamburguer-collapsed-header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
  border-bottom: 1px solid #efefef;
}

.menu-hamburguer-collapsed .menu-hamburguer-collapsed-menu {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-hamburguer-collapsed
  .menu-hamburguer-collapsed-menu
  .menu-hamburguer-collapsed-menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.menu-hamburguer-collapsed .menu-hamburguer-collapsed-menu li {
  list-style: none;
  margin-bottom: 50px;
}

.menu-hamburguer-collapsed .menu-hamburguer-collapsed-menu li a {
  font-size: 20px;
  font-weight: 300;
  text-decoration: none;
  color: #626262;
}

.menu-hamburguer-collapsed .menu-hamburguer-collapsed-menu li a:hover {
  opacity: 0.6;
  transition: all 0.4s ease-in-out;
}

@media all and (max-width:360px) {

}