.published-comic {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 300px;
  height: 430px;
  margin: 30px;
  border-radius: 15px;
  text-decoration: none;
  transition: 0.2s;
}

#comic-scroll:hover {
  opacity: 0.7;
}

.comic-page-input {
  width: 600px !important;
}

.published-comic:hover {
  height: 420px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  transition: 0.2s;
}
.published-comic-title {
  margin: 30px;
  color: white;
  font-size: 18px;
  font-weight: 400;
}


@media all and (max-width: 360px) {
  .comic-input {
    min-width: 320px;
    margin-bottom: 50%;
  }
  .published-comic {
    margin: 15px;
  }
}

@media all and (max-width: 600px) {
  .comic-page-input {
    width: 100% !important
  }
}
